* {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  /* font-family: "Figtree", sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "geomanist"; */
  /* font-family: "Metropolis"; */
}
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

@font-face {
  font-family: "geomanist";
  src: url("./geomanist.ttf");
}
@font-face {
  font-family: "Metropolis";
  src: url("./Metropolis.otf");
}

.category-button {
  padding: 10px 15px;
  /* border: 1px solid #ffc412; */
  border-radius: 5px;
  background-color: black;
  cursor: pointer;
  white-space: nowrap;
  margin: 0 5px;
  transition: background-color 0.3s, color 0.3s;
}

.category-button.active {
  /* filter: invert(1); */
  color: black;
  border-color: black;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-prev,
.swiper-button-next {
  color: #ffc412 !important;
}
.swiper-pagination-bullet {
  background-color: #ffff !important;
  width: 13px !important;
  height: 13px !important;
  margin: 0 8px !important;
  position: relative;
  top: 0;
}

/* Change active pagination bullet color */
.swiper-pagination-bullet-active {
  background-color: #ffc412 !important;
}

/* Appropriate vendor prefixes should be added for broader browser support */

/* Hide the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #fff; /* white background */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #000; /* black handle */
  border-radius: 10px; /* roundness of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* darker black on hover */
}

/* styles.css */
/* styles.css */
.vendor-card {
  position: relative;
  width: 300px; /* Adjust width as needed */
  height: 400px; /* Adjust height as needed */
  perspective: 1000px; /* Perspective for 3D effect */
}

.vendor-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.vendor-card-front,
.vendor-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.vendor-card-front {
  z-index: 2;
  transform: rotateY(0deg);
  background-color: #fff; /* Front side background */
}

.vendor-card-back {
  transform: rotateY(180deg);
  background-color: #f0f0f0; /* Back side background */
}

.vendor-card.flipped .vendor-card-inner {
  transform: rotateY(180deg);
}

.leaflet-popup-content-wrapper {
  background-color: black;
}
