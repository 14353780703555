@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
html {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  /* background-color: #f6f6f6; */
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

.TabNavigationContainer {
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  transform: translate(-50%, 0);
  height: 60px;
  display: flex;
  padding-left: 28px;
  padding-right: 28px;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  font-weight: bold;
  /* border-radius: 38px; */
  box-shadow: 0px -2px 4px rgba(255, 255, 255, 0.1);
}

.TabNavigationLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  height: 4rem;
  width: 4rem;
  color: #ffc412; /* Default color for inactive icons */
  margin: 0 10px;
  transition: color 0.4s ease-in;
}

.TabNavigationLink.active {
  color: #ffa200; /* Color for active icon */
}

.TabNavigationLink:hover {
  color: #ffa200;
  font-weight: 700;
}

.active {
  color: #0284c7;
}

@media (max-width: 768px) {
  .TabNavigationContainer {
    justify-content: space-between;
  }
}
